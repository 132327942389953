import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Section, Title } from "../../components/Core";
import CaseCard from "../../components/CaseCard";

// @ts-expect-error false positive image import failure
import imgCase1 from "../../assets/image/case-studies/southend-small.jpg";
// @ts-expect-error false positive image import failure
import imgCase2 from "../../assets/image/case-studies/west-suffolk-small.jpg";
// @ts-expect-error false positive image import failure
import imgBrand1 from "../../assets/image/case-studies/southend-logo.png";
// @ts-expect-error false positive image import failure
import imgBrand2 from "../../assets/image/case-studies/west-suffolk-logo.png";

const CaseList2 = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className="position-relative ">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="6" className="mb-5 mb-lg-0">
            <CaseCard
              img={imgCase1}
              imgBrand={imgBrand1}
              title="Southend-on-Sea"
              to="/southend-case-study"
            >
              The ‘Intelligence Hub’ will transform how services are delivered
              and offering potential for revenue generation.
            </CaseCard>
          </Col>
          <Col lg="6" className="mb-0">
            <CaseCard
              img={imgCase2}
              imgBrand={imgBrand2}
              title="West Suffolk Council"
              to="/west-suffolk-case-study"
            >
              Building a new CCTV Control room demanded significant investment
              and a solid business case to proceed.
            </CaseCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseList2;
