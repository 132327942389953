import React from "react";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/bids-councils/Hero";
import Content from "../sections/bids-councils/Content";
import CaseList2 from "../sections/bids-councils/CaseList2";
import CTA from "../sections/bids-councils/CTA";
import Feature from "../sections/bids-councils/Feature";

const About = () => {
  return (
    <>
      <PageWrapper headerDark>
        <Helmet>
          <title>BIDs, CRPs &amp; Councils</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero title="BIDs, CRPs &amp; Councils"></Hero>
        <Content />
        <CaseList2 />
        <Feature />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default About;
