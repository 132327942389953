import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import pdfBrochure from "../../assets/downloads/ShopSafe-ShopWatch-and-TownLink-Brochure.pdf";

// @ts-expect-error false positive image import failure
import imgContent1 from "../../assets/image/jpeg/easy-image-2-1.jpg";
// @ts-expect-error false positive image import failure
import imgContent2 from "../../assets/image/jpeg/easy-image-2-2.jpg";

interface ThemeProps {
  theme: DefaultTheme;
}

const ContentImg = styled(Box)<ThemeProps>`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content = () => (
  <>
    <Section>
      <Container>
        <Row>
          <Col lg="6">
            <Title variant="hero">
              Our mission is to make your life easier.
            </Title>
          </Col>
          <Col lg="6" className="pl-lg-5 pt-lg-4">
            <Text>
              Working in partnership with BIDs, crime partnerships and local
              authorities in the fight against town and city centre crime.
              Connecting communities through instant group communication and
              information sharing apps.
            </Text>
            <a href={pdfBrochure} download>
              <Button mt={4} variant="solid" color="light" mr={3}>
                Download Our Brochure
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content;
