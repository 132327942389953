import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section } from "../../components/Core";
import imgCase1 from "../../assets/image/heros/town.png";

interface Props {
  title?: string;
}

const SectionStyled = styled(Section)`
  background: url(${imgCase1}) no-repeat;
  background-size: cover;
  background-position: center right;
  position: relative;
`;

const HeroOverlay = styled(Section)`
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.3;
`;

const Hero: React.FC<Props> = ({ title, children, ...rest }) => {
  return (
    <>
      <SectionStyled
        pt={["110px!important", null, "170px!important"]}
        pb={["50px!important", null, "120px!important"]}
        {...rest}
      >
        <HeroOverlay />
        <Container>
          <Row>
            <Col lg="6">
              <div>
                <Title variant="hero" color="light">
                  {title}
                </Title>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};
export default Hero;
