import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { DefaultTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsersMedical,
  faMobile,
  faClock,
  faGraduationCap,
  faCommentsQuestionCheck,
  faWalkieTalkie,
} from "@fortawesome/pro-light-svg-icons";

import { Title, Section, Box, Text } from "../../components/Core";
import { ColorTypes } from "../../utils";

interface FeatureCardProps {
  color?: string;
  icon?: any;
  title?: string;
  children: React.ReactNode;
}

interface ThemeProps {
  theme: DefaultTheme;
  color: keyof ColorTypes;
  children: React.ReactNode;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  color = "primary",
  icon,
  title,
  children,
  ...rest
}) => (
  <Box pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
    <Box
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center mx-auto"
      css={`
        background-color: ${({ theme, color }: ThemeProps) =>
          rgba(theme.colors[color], 0.1)};
      `}
    >
      <FontAwesomeIcon icon={icon} />
    </Box>
    <div className="text-center">
      <Text
        color="heading"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className="position-relative" mt={-3}>
      <Container>
        <Row className="mb-0 text-center">
          <Col lg="12">
            <Title>How it works</Title>
          </Col>
        </Row>
        <Row className="align-items-start justify-content-center">
          <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5 ">
            <FeatureCard
              color="primary"
              icon={faWalkieTalkie}
              title="Latest Devices"
            >
              We use the very best digital products to provide members with
              better voice clarity along with key features such as panic alarm,
              Colour Display and GPS.
            </FeatureCard>
          </Col>

          <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faCommentsQuestionCheck}
              title="Next Day Service"
            >
              Be it a battery, antenna, charger or a new radio we send a
              replacement to arrive next day with all the return packaging for
              your faulty part or unit. The best bit, there’s no extra cost!
            </FeatureCard>
          </Col>

          <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faMobile}
              title="Secure Information Sharing"
            >
              Our Alert app provides a safe a secure method for sharing
              sensitive or private information amongst your members or team.
              Offered at no additional cost to subscribing businesses and
              partnership co-branding available.
            </FeatureCard>
          </Col>

          <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard color="primary" icon={faClock} title="24/7 Support">
              Our expert team of engineers are standing by to support your
              network day and night, ensuring your system is live when your
              users need it most.
            </FeatureCard>
          </Col>
          <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faGraduationCap}
              title="Comprehensive Training"
            >
              Onsite and bespoke training for members and partnerships. We also
              have a library of intuitive airline style instruction sheets and
              quick start videos.
            </FeatureCard>
          </Col>

          <Col sm="6" md="6" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faUsersMedical}
              title="User Generation"
            >
              Growing the scheme benefits everyone. We will undertake telephone
              canvassing, attend local meetings and even deploy our ShopSafe
              ambassadors around the town to promote and grow the scheme.
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Feature;
